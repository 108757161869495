import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { API_URL } from "../../config";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

// // Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Destination = ({ categoryName, categoryId }) => {
  const [destination, setDestination] = useState([]);

  useEffect(() => {
    async function getDestination() {
      const response = await axios.get(
        `${API_URL}/destination/category/${categoryId}`
      );
      setDestination(response.data.data);
    }
    getDestination();
  }, [categoryId]);

  const getDestinationOverview = (desc) => {
    const lenChar = 150;
    let destinationOverview = "";

    for (let i = 0; i <= lenChar; i++) {
      destinationOverview += desc[i];
    }

    return destinationOverview;
  };

  const srcThumbnail = (element) => {
    return element[0]
      ? element[0].media_uri
      : "https://wallpaperaccess.com/full/212205.jpg";
  };
  const altThumbnail = (element) => {
    return element[0] ? element[0].name : "merayu-kelapa-photo";
  };

  return (
    <>
      <div className='container mx-auto'>
        <div className='flex flex-wrap'>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation]}
            className='mySwiper'
          >
            {destination.map((e) => {
              return (
                <SwiperSlide
                  className='rounded-lg mb-10'
                  key={e?.destination_id}
                >
                  <div className='flex flex-col bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden'>
                    <div className='flex h-52'>
                      <img
                        className='inset-0 object-cover object-center hover:scale-105 hover:duration-300'
                        src={srcThumbnail(e.picture)}
                        alt={altThumbnail(e.picture)}
                      ></img>
                    </div>
                    <div className='p-4 h-3/4'>
                      <div className='flex gap-1 justify-end'>
                        {e.tag?.map((t, idx) => {
                          return (
                            <span
                              className='inline-block px-2 py-2 leading-none bg-orange-200 text-orange-800 rounded-full tracking-wide text-xs-1/2'
                              key={idx}
                            >
                              #{t?.name}
                            </span>
                          );
                        })}
                      </div>
                      <div className='h-1/6'>
                        <h2 className='font-bold text-center mt-2'>{e.name}</h2>
                      </div>
                      <div className='h-2/5'>
                        <p
                          className='text-center mt-1'
                          style={{ fontSize: "14px" }}
                        >
                          {getDestinationOverview(e.description)} ...
                        </p>
                      </div>
                      <div className='flex justify-center h-auto'>
                        <Link
                          to={`destination/${e?.destination_id}`}
                          className='my-5 mx-0 inline-block px-4 py-3 leading-none bg-[#36606e] hover:bg-[#142328] text-white rounded-full tracking-wide text-xs-3/4 items-center'
                        >
                          Selengkapnya
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Destination;
