/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  CollectionIcon,
} from "@heroicons/react/outline";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const wisata = [
  // {
  //   name: "Harapan",
  //   description: "Harapan punya daya tarik tersendiri",
  //   href: "#wisata",
  //   icon: CollectionIcon,
  // },
  // {
  //   name: "Kelapa",
  //   description: "Kelapa mempunyai penduduk terbanyak",
  //   href: "#wisata",
  //   icon: CollectionIcon,
  // },
  {
    name: "Lancang",
    description: "Lancang memiliki keindahan terpendam",
    href: "/lancang/informasi",
    icon: CollectionIcon,
  },
  // {
  //   name: "Sabira",
  //   description: "Sabiran merupakan pulau yang unik",
  //   href: "#wisata",
  //   icon: CollectionIcon,
  // },
];

const fitur = [
  {
    name: "Penginapan",
    to: "/homestay-list",
  },
  // {
  //   name: "Katering",
  //   to: "/catering-list",
  // },
  // {
  //   name: "Produk",
  //   to: "/product-list",
  // },
  {
    name: "Tentang",
    to: "/about",
  },
  // {
  //   name: "Kontak",
  //   to: "/",
  // },
];

const TranslateNav = () => {
  return (
    <div className='items-center justify-end hidden lg:flex lg:flex-1 lg:w-0'>
      <Link
        to='#'
        className='font-medium text-gray-500 uppercase whitespace-nowrap text-nav-1 hover:text-gray-900'
      >
        ID
      </Link>
      <pre className='font-medium text-gray-500 text-nav-1'> | </pre>
      <Link
        to='#'
        className='font-medium text-gray-500 uppercase whitespace-nowrap text-nav-1 hover:text-gray-900'
      >
        EN
      </Link>
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navigation = () => {
  return (
    <>
      {/* <Popover className='sticky top-0 z-50 hidden bg-white lg:block'> */}
      <Popover className='sticky top-0 z-50 bg-white '>

        {/* Desktop Header */}
        <div className='px-4 mx-auto max-w-7xl sm:px-6'>
          <div className='flex items-center justify-between py-4 border-b-2 border-gray-100 lg:justify-start lg:space-x-10'>
            <Link
              to='/'
              className='text-teal-700 font-title lg:text-[35px] text-[20px]'
            >
              Seribu Pesona
            </Link>

            {/* Mobile header button */}
            <div className='-my-2 -mr-2 lg:hidden'>
              <Popover.Button className='inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                <span className='sr-only'>Open menu</span>
                <MenuIcon className='w-6 h-6' aria-hidden='true' />
              </Popover.Button>
            </div>

            {/* Desktop header item */}
            <Popover.Group as='nav' className='hidden space-x-5 lg:flex'>
              <Link
                to='/'
                className='px-2 py-1 font-medium text-gray-500 rounded-md text-nav-1 hover:text-gray-900 hover:bg-gray-200'
              >
                Beranda
              </Link>

              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "px-2 py-1 group bg-white text-nav-1 rounded-md inline-flex items-center font-medium hover:text-gray-900 focus:outline-none hover:bg-gray-200"
                      )}
                    >
                      <span>Wisata</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute z-10 w-screen max-w-sm px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'>
                        <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                          <div className='relative grid gap-4 px-5 py-4 bg-white sm:gap-8 sm:p-8'>
                            {wisata.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className='flex items-start p-2 -m-3 rounded-lg hover:bg-gray-50'
                              >
                                <item.icon
                                  className='flex-shrink-0 w-5 h-5 text-teal-700'
                                  aria-hidden='true'
                                />
                                <div className='ml-4'>
                                  <p className='font-medium text-gray-900 text-nav-1'>
                                    {item.name}
                                  </p>
                                  <p
                                    className='mt-1 text-gray-500 text-[12-px]'
                                  >
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              {fitur.map((e) => {
                return (
                  <Link
                    key={e.name}
                    to={e.to}
                    className='px-2 py-1 font-medium text-gray-500 rounded-md text-nav-1 hover:text-gray-900 hover:bg-gray-200'
                  >
                    {e.name}
                  </Link>
                );
              })}
            </Popover.Group>
            {/* <TranslateNav /> */}
          </div>
        </div>


        {/* Mobile Header */}
        <Transition
          as={Fragment}
          enter='duration-200 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            focus
            className='absolute inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden'
          >
            <div className='bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50'>
              <div className='px-5 pt-5 pb-6'>
                <div className='flex items-center justify-between'>
                  <div>
                    <img
                      className='hidden w-auto h-8'
                      src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
                      alt='Workflow'
                    />
                  </div>
                  <div className='-mr-2'>
                    <Popover.Button className='inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                      <span className='sr-only'>Close menu</span>
                      <XIcon className='w-6 h-6' aria-hidden='true' />
                    </Popover.Button>
                  </div>
                </div>
                <div className='mt-6'>
                  <nav className='grid gap-y-6'>
                    {wisata.map((item) => (
                      <a
                        key={item.name}
                        href={item.to}
                        className='flex items-center p-3 -m-3 rounded-md hover:bg-gray-50'
                      >
                        <item.icon
                          className='flex-shrink-0 w-4 h-4 text-indigo-600'
                          aria-hidden='true'
                        />
                        <span className='ml-3 font-medium text-gray-900 text-nav-1'>
                          {item.name}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className='px-5 py-6 space-y-6'>
                <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                  <a
                    href='/'
                    className='text-base font-medium text-gray-900 hover:text-gray-700'
                  >
                    Beranda
                  </a>

                  <a
                    href='/about'
                    className='text-base font-medium text-gray-900 hover:text-gray-700'
                  >
                    Tentang Kami
                  </a>
                  {fitur.map((item) => (
                    <a
                      key={item.name}
                      href={item.to}
                      className='text-base font-medium text-gray-900 hover:text-gray-700'
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default Navigation;
