export const homestayData = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%20amira.jpg?alt=media&token=bfdaba9b-9f8a-4d78-8ee3-2ce062871b7e",
    alt: "Homestay Amira",
    to: "/homestay-detail/1",
    name: "Homestay Amira",
    room: "2 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/hoestay%20putra-putri.jpg?alt=media&token=ed3d6526-7c8a-403c-b759-0db673b5460d",
    alt: "Homestay Putra-Putri",
    to: "/homestay-detail/2",
    name: "Homestay Putra-Putri",
    room: "1 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%20bu%20haji%20iti.jpg?alt=media&token=32a291c3-6e10-4764-b7f7-33ccb65a6757",
    alt: "Homestay Bu Haji Siti",
    to: "/homestay-detail/3",
    name: "Homestay Bu Haji Siti",
    room: "? Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%20angin%20mamiri.jpg?alt=media&token=ee0f0351-1f4a-4eb1-9186-1a3b30f4f716",
    alt: "Homestay Angin Mamiri",
    to: "/homestay-detail/4",
    name: "Homestay Angin Mamiri",
    room: "3 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%203%20bersaudara.jpg?alt=media&token=33938c39-b12c-4d48-bb74-1d87d7e9acc2",
    alt: "Homestay 3 Bersaudara",
    to: "/homestay-detail/5",
    name: "Homestay 3 Bersaudara",
    room: "2 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%20faiz.jpg?alt=media&token=e4931c9c-f050-4a5b-b0ba-3a73bb348b47",
    alt: "Homestay Faiz",
    to: "/homestay-detail/6",
    name: "Homestay Faiz",
    room: "1 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/20230128_105424202_iOS.jpg?alt=media&token=81fdaa81-76cd-48f2-9c65-e1ce463d5876",
    alt: "Homestay Bang Prana",
    to: "/homestay-detail/7",
    name: "Homestay Bang Prana",
    room: "4 Kamar",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/homestay%20panorama.jpg?alt=media&token=b67f8605-1aff-4339-a355-9e7aeffb9a27",
    alt: "Homestay Panorama",
    to: "/homestay-detail/8",
    name: "Homestay Panorama",
    room: "1 Kamar",
  },
];
