import axios from "axios";
import React, { useState, useEffect } from "react";
import DestinationList from "../Destination/DestinationList";

const InformationCenter = () => {
  return (
    <>
      <div className="p-6 font-sans antialiased main">
        <div>
          <div id="card" className="">
            <h2
              className="text-3xl font-bold text-center text-white font-title xl:text-4xl"
              style={{ fontSize: "40px" }}
            >
              Tourism Information Center
            </h2>
            <h2
              className="text-3xl font-bold text-center text-white font-title xl:text-4xl"
              style={{ fontSize: "40px" }}
            >
              Lancang Island
            </h2>
            <div className="container flex flex-col mx-auto my-5 w-100 lg:w-4/5">
              <div
                v-for="card in cards"
                className="flex flex-col mx-2 mt-4 overflow-hidden bg-white rounded-lg shadow-xl md:flex-row w-100"
              >
                <div className="flex justify-center inset-0 bg-center bg-cover h-screen w-full bg-[url('https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/Peta%20Lokasi%20Wisata%20Pulau%20Lancang%204.png?alt=media&token=bd26e132-4e32-4cf0-bda3-1887de61cebc')]"></div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <DestinationList />
        </div>
      </div>
    </>
  );
};

export default InformationCenter;
