import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../config";

const DestinationDetail = () => {
  const { destinationId } = useParams();
  const [destination, setDestination] = useState([]);

  // useEffect(() => {
  //   const getDestination = async () => {
  //     const result = await axios.get(`${API_URL}/destination/${destinationId}`);
  //     setDestination(result.data.data);
  //   };

  //   getDestination();
  // }, [destinationId]);

  const data_destination = [
    {
      name: "Pantai Gusung Klanceng",
      description:
        "Gusung Klanceng merupakan endalan seluas 1.260 meter persegi yang menonjol ke permukaan laut. Gusung merupakan spot wisata baru di pulau lancang. Gusung Klanceng cocok untuk dijadikan sebagai tempat swafoto, berkemah, memancing maupun menunggu matahari terbit dan tenggelam.",
      picture: [
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/gusung1.jpg?alt=media&token=b5d6495b-4b1e-45bc-9f22-5822be0357e5",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/gusung2.jfif?alt=media&token=1f4a7273-4d03-40eb-8089-bfc0593997ca",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/gusung3.jpg?alt=media&token=a79b0bb1-8745-4db8-bd02-61ab5bdeb77f",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/gusung4.jpg?alt=media&token=b77832be-c6d3-410d-9478-b93a73ffc367",
          name: "Pantai Karang Mangrove 1",
        },
      ],
    },
    {
      name: "Pantai Karang Mangrove",
      description:
        "Pantai Karang Mangrove -lebih populer dengan sebutan Karma- merupakan salah satu spot wisata andalan Pulau Lancang. Ingin menikmati matahari terbenam? Kunjungi Pantai Karma di sisi Barat Pulau Lancang",
      picture: [
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/karma1.jpg?alt=media&token=832c8d74-9d97-4c28-a841-821f3c7fb955",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/karma5.JPG?alt=media&token=66a882ff-ddbc-4029-bd3d-27879ab06002",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/karma4.JPG?alt=media&token=b7d64b9b-af57-4ff5-b6cb-19979ec9faff",
          name: "Pantai Karang Mangrove 1",
        },
        {
          media_uri:
            "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/karma3.JPG?alt=media&token=b228e4d2-b674-444a-879e-56f0dae81dcf",
          name: "Pantai Karang Mangrove 1",
        },
      ],
    },
  ];

  useEffect(() => {
    if (destinationId === "gusung") {
      setDestination(data_destination[0]);
    } else if (destinationId === "karma") {
      console.log("im here");
      setDestination(data_destination[1]);
    }
  }, [destinationId]);

  const thumbnail = destination.picture?.map((e, idx) => {
    let rowSpan = idx === 0 || idx === 3 ? "row-span-2" : "";
    return (
      <div className={`w-full ${rowSpan}`} key={idx}>
        <img
          src={e.media_uri}
          alt={e.name}
          className={`inset-0 object-cover object-center w-full h-full opacity-75 hover:opacity-100 ${(rowSpan =
            idx === 0 || idx === 3 ? "max-h-[562px]" : "max-h-[280px]")}`}
        ></img>
      </div>
    );
  });

  return (
    <>
      <body className="main">
        <div className="container px-2 py-4 mx-auto lg:px-32">
          <div className="gap-2">
            <h1
              className="mb-4 font-bold text-white font-title"
              style={{ fontSize: "30px" }}
            >
              {destination?.name}
            </h1>
          </div>
          <div className="grid grid-flow-col grid-cols-3 grid-rows-2 gap-2 overflow-hidden rounded-lg">
            {thumbnail}
          </div>
          <div className="gap-2 py-4 text-white">
            <h2 className="mt-4 font-bold">Deskripsi</h2>
            <p className="mt-2 text-justify" style={{ fontSize: "14px" }}>
              {destination?.description}
            </p>
          </div>
        </div>
      </body>
    </>
  );
};

export default DestinationDetail;
