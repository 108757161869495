import axios from "axios";
import React, { useState, useEffect } from "react";

const InformationCenter = () => {
    return (
        <div className='main antialiased font-sans p-6'>
            <div>
                <div id='card' className=''>
                    <h2
                        className='text-center font-title text-3xl xl:text-4xl text-white font-bold'
                        style={{ fontSize: "40px" }}
                    >
                        Tourism Information Center
                    </h2>
                    <h2
                        className='text-center font-title text-3xl xl:text-4xl text-white font-bold'
                        style={{ fontSize: "40px" }}
                    >
                        Harapan Island
                    </h2>
                    <div className='container w-100 lg:w-4/5 mx-auto my-5 flex flex-col'>
                        <div
                            v-for='card in cards'
                            className='flex flex-col md:flex-row overflow-hidden
                                                bg-white rounded-lg shadow-xl mt-4 w-100 mx-2'
                        >
                            <div className="flex justify-center inset-0 bg-center bg-cover h-screen w-full bg-[url('https://seribu-utara-media.s3.ap-southeast-1.amazonaws.com/images/TIC/tourism-map-harapan.png')]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformationCenter;