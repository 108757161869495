import React from "react";

const About = () => {
    return (
        <div className='main antialiased font-sans p-6'>
            <h1
                className='text-center font-title text-3xl xl:text-4xl text-white font-bold'
                style={{ fontSize: "40px" }}
            >
                Tentang Seribu Pesona
            </h1>
            <div className="flex w-11/12 bg-white mt-4 gap-5 rounded mx-auto overflow-hidden">
                <div className="flex w-2/5 h-full">
                    <img
                        className='inset-0 h-full w-full object-cover object-center'
                        src='https://seribu-utara-media.s3.ap-southeast-1.amazonaws.com/images/Destination/pulau-seribu-utara.jpg'
                        alt=''
                    />
                </div>
                <div className="flex flex-col p-4 gap-2 w-3/5 h-full text-justify">
                    <h1 className="font-title text-teal-700" style={{ fontSize: "40px" }}>Seribu Pesona</h1>
                    <p className="first-letter:text-xl-1/2 first-letter:font-bold first-letter:text-slate-900 first-letter:mr-3 first-letter:float-left">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, repellendus odio. Aliquam, rem, impedit dolorum saepe at mollitia tenetur asperiores, sed quia ex porro placeat eligendi minima alias similique natus.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;