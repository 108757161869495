import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import { API_URL } from "../../config";

const CateringCategory = () => {
  const { categoryId } = useParams();
  const [catering, setCatering] = useState([]);

  useEffect(() => {
    async function getCatering() {
      const response = await axios.get(
        `${API_URL}/catering/category/${categoryId}`
      );
      setCatering(response.data.data);
    }
    getCatering();
  }, [categoryId]);

  const srcThumbnail = (element) => {
    return element[0]
      ? element[0].media_uri
      : "https://www.fodors.com/wp-content/uploads/2019/04/TraditionalfoodsinJava__HERO_shutterstock_1161085942.jpg";
  };
  const altThumbnail = (element) => {
    return element[0] ? element[0].name : "merayu-kelapa-photo";
  };

  const cardCatering = catering.map((e, idx) => {
    return (
      <div id='card' key={idx}>
        <div className='container w-100 lg:w-4/5 mx-auto flex flex-col'>
          <div
            v-for='card in cards'
            className='flex flex-col md:flex-row overflow-hidden
                                                    bg-white rounded-lg shadow-xl mt-5 w-100 mx-2'
          >
            <div className='h-64 w-auto md:w-1/2'>
              <img
                className='inset-0 h-full w-full object-cover object-center'
                src={srcThumbnail(e.picture)}
                alt={altThumbnail(e.picture)}
              />
            </div>
            <div className='w-full py-4 px-6 text-gray-800 flex flex-col justify-between'>
              <div className='flex'>
                <h3 className='font-semibold text-lg leading-tight truncate'>
                  {e.name}
                </h3>
              </div>
              <div className='flex mt-2'>
                <HomeIcon className='h-5 w-5' />
                <p className='px-1'>{e.address}</p>
              </div>
              <div className='flex mt-2'>
                <p className='px-1'>{e.description}</p>
              </div>
              <div className='flex mt-2'>
                <p className='px-1'>{e.ecommerce_link}</p>
              </div>
              <div className='flex mt-2'>
                <FontAwesomeIcon
                  icon={faPhone}
                  className='h-4 w-4 justify-center'
                />
                <p className='text-sm text-gray-700 tracking-wide font-semibold px-1'>
                  {e.owner} &bull; {e.phone_number}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  if (catering.length > 0) {
    const islandName = catering[0]?.category_island.name;
    return (
      <>
        <div className='main antialiased font-sans p-6'>
          <div>
            <div id='card' className=''>
              <h2
                className='text-center font-title text-3xl xl:text-4xl text-white'
                style={{ fontSize: "40px" }}
              >
                Katering Pulau {islandName}
              </h2>
              {cardCatering}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className='main antialiased font-sans p-6'>
        <div>
          <div id='card' className=''>
            <h2
              className='text-center font-title text-3xl xl:text-4xl text-white'
              style={{ fontSize: "40px" }}
            >
              Katering
            </h2>
            <div className='container w-100 lg:w-4/5 mx-auto flex flex-col'>
              <div
                v-for='card in cards'
                className='flex flex-col md:flex-row overflow-hidden
                                                    bg-white rounded-lg shadow-xl mt-5 w-100 mx-2'
              >
                <div className='h-64 w-auto md:w-1/2'>
                  <img
                    className='inset-0 h-full w-full object-cover object-center'
                    src='https://images.unsplash.com/photo-1475855581690-80accde3ae2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'
                    alt=''
                  />
                </div>
                <div className='w-full py-4 px-6 text-gray-800 flex flex-col justify-between'>
                  <div className='flex'>
                    <h3 className='font-semibold text-lg leading-tight truncate'>
                      Katering
                    </h3>
                  </div>
                  <div className='flex'>
                    <p className='mt-2'>Alamat</p>
                  </div>
                  <div className='flex'>
                    <p className='mt-2'>Deskripsi</p>
                  </div>
                  <div className='flex'>
                    <p className='mt-2'>E-Commerce Link</p>
                  </div>
                  <div className='flex'>
                    <p className='text-sm text-gray-700 uppercase tracking-wide font-semibold mt-2'>
                      Pemilik &bull; No. Telp
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CateringCategory;
