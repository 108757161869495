import React, { useEffect, useState } from "react";
import axios from "axios";

import DestinationList from "../Destination/DestinationList";
import Hero from "../Hero";
import HomestayList from "../Homestay/HomestayList";

import { API_URL } from "../../config";

const Home = () => {
  const [categoryIsland, setCategoryIsland] = useState([]);

  useEffect(() => {
    const getCategoryIsland = async () => {
      const result = await axios.get(`${API_URL}/category-island`);
      setCategoryIsland(result.data.data);
    };

    getCategoryIsland();
  }, []);

  return (
    <>
      <Hero />
      <div className='home antialiased bg-gray-200 text-gray-900 font-sans p-6'>
        <DestinationList categoryIsland={categoryIsland} />
        <HomestayList />
      </div>
    </>
  );
};

export default Home;
