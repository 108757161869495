import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL } from "../../config";

import {
  LocationMarkerIcon,
  UserIcon,
  TicketIcon,
} from "@heroicons/react/solid";

import {
  faDoorClosed,
  faBed,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const HomestayCategory = () => {
  const { categoryId } = useParams();
  const [homestay, setHomestay] = useState([]);

  useEffect(() => {
    async function getHomestay() {
      const response = await axios.get(
        `${API_URL}/homestay/category/${categoryId}`
      );
      setHomestay(response.data.data);
    }
    getHomestay();
  }, [categoryId]);

  console.log("kerender bapak", categoryId)

  const CardHomestay = ({ elmenent }) => {
    return (
      <Link
        to={`/homestay-detail/${elmenent?.homestay_id}`}
        className='flex w-5/12 h-56 overflow-hidden rounded-lg hover:shadow-2xl hover:duration-500 hover:scale-110'
      >
        <div className='flex w-5/12 h-full'>
          <img
            className='inset-0 object-cover object-center w-full h-full'
            src='https://images.unsplash.com/photo-1475855581690-80accde3ae2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'
            alt=''
          />
        </div>
        <div className='flex flex-col w-7/12 h-full p-3 bg-white'>
          <div className='flex justify-between pb-2 mb-2 border-b-2'>
            <div className='flex flex-col'>
              <div className='text-lg font-semibold leading-tight'>
                {elmenent?.name}
              </div>
              <div className='flex items-center justify-start text-gray-400 text-xs-1/2'>
                <LocationMarkerIcon className='w-3 h-3' />
                <p className='px-1'>{elmenent?.address}</p>
              </div>
            </div>
            <p className='flex items-center -mt-4 font-semibold text-gray-700 text-xs-3/4'>
              <UserIcon className='w-3 h-3' />
              <p className='px-1'>{elmenent?.owner}</p>
            </p>
          </div>
          <div
            className='flex flex-col flex-wrap justify-center w-full gap-4 p-4 bg-slate-100 h-3/5'
            style={{ fontSize: "13px" }}
          >
            <div className='flex h-4'>
              <FontAwesomeIcon icon={faBed} className='w-4 h-4' />
              <div className='px-1'>{elmenent?.room} kamar</div>
            </div>
            <div className='flex h-4'>
              <FontAwesomeIcon icon={faDoorClosed} className='w-4 h-4' />
              <div className='px-1'>{elmenent?.door} pintu</div>
            </div>
            <div className='flex h-4'>
              <TicketIcon className='w-4 h-4' />
              <div className='px-1'>{elmenent?.capacities} kapasitas</div>
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <div className='flex items-center justify-center text-gray-500 text-xs-3/4'>
              <div className='flex items-center'>
                <FontAwesomeIcon icon={faPhone} className='w-3 h-3' />
                <p className='px-1'>{elmenent?.phone_number}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  if (homestay.length) {
    const islandName = homestay[0]?.category_island.name;
    return (
      <div className='p-6 font-sans antialiased main'>
        <h2
          className='text-3xl text-center text-white font-title xl:text-4xl'
          style={{ fontSize: "40px" }}
        >
          Penginapan Pulau {islandName}
        </h2>
        <div className='flex flex-wrap justify-center w-11/12 gap-4 p-2 mx-auto mt-4'>
          {homestay.map((e, idx) => {
            return <CardHomestay elmenent={e} key={idx} />;
          })}
        </div>
      </div>
    );
  }
};

export default HomestayCategory;
