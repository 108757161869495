import React, { useRef, useEffect } from "react";
import videoBG from "./video.mp4";

const Hero = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="relative w-full max-w-full mx-auto h-fit">
      <video
        className="w-full shadow-lg"
        // className='w-auto h-screen shadow-lg'
        loop
        muted
        alt="All the devices"
        src={videoBG}
        ref={videoEl}
      />
      {/* absolute flex flex-col items-center w-3/5 gap-1 ml-8 -mt-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 h-3/5 */}
      <div className="absolute left-0 right-0 flex flex-col items-center w-3/5 gap-1 mx-auto -translate-y-1/2 top-1/2">
        <div className="flex items-center w-3/5 h-1/5">
          <span className="px-2 font-thin text-white bg-blue-800 rounded-lg font-title lg:text-[20px] hidden sm:block">
            seribu
          </span>{" "}
          <span className="px-6 font-bold tracking-widest text-white underline drop-shadow-lg underline-offset-4 lg:text-[45px] md:text-[30px] text-[20px]">
            Pulau.
          </span>
        </div>

        <div className="flex items-center w-3/5 h-1/5">
          <span className="px-2 font-thin text-white bg-blue-800 rounded-lg font-title lg:text-[20px] hidden sm:block">
            seribu
          </span>{" "}
          <span className="px-6 font-bold tracking-widest text-white underline drop-shadow-lg underline-offset-4 lg:text-[80px] md:text-[40px] text-[30px]">
            Pesona.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
