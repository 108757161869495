import React from "react";
import { Link } from "react-router-dom";
import { homestayData } from "./homestayData";

const HomestayList = () => {
  return (
    <>
      <div className="mt-4 mb-4 font-bold text-center text-white font-title text-title-section xl:text-5xl">
        Homestay
      </div>

      <div className="grid w-4/5 grid-cols-3 grid-rows-3 gap-4 p-4 mx-auto overflow-hidden rounded-lg">
        {homestayData.map((data, index) => (
          <Link
            to={data.to}
            className="relative w-full hover:shadow-lg"
            key={index}
          >
            <>
              <div className="absolute top-0 bottom-0 left-0 right-0 z-40 h-full text-lg text-white align-baseline bg-black/[0.50] hover:bg-black/[0.60]">
                <p className="absolute left-0 right-0 z-50  top-[50%] translate-y-[-50%] text-center">
                  {data.name}
                </p>
              </div>
              <img
                src={data.img}
                alt={data.alt}
                className="inset-0 object-cover object-center w-full h-full rounded-lg hover:shadow-lg hover:scale-110 hover:duration-300"
              ></img>
            </>
          </Link>
        ))}
      </div>
    </>
  );
};

export default HomestayList;
