import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer/Footer";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Homestay from "./pages/Homestay/Homestay";
import HomestayCategory from "./pages/Homestay/HomestayCategory";
import DestinationDetail from "./pages/Destination/DestinationDetail";
import HomestayDetail from "./pages/Homestay/HomestayDetail";
import Product from "./pages/Product/Product";
import ProductCategory from "./pages/Product/ProductCategory";
import Catering from "./pages/Catering/Catering";
import CateringCategory from "./pages/Catering/CateringCategory";
import InformationCenterSabira from "./pages/InformationCenter.jsx/InformationCenterSabira";
import InformationCenterKelapa from "./pages/InformationCenter.jsx/InformationCenterKelapa";
import InformationCenterHarapan from "./pages/InformationCenter.jsx/InformationCenterHarapan";
import InformationCenterLancang from "./pages/InformationCenter.jsx/InformationCenterLancang";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/destination">
          <Route path=":destinationId" element={<DestinationDetail />} />
        </Route>
        <Route path="/about" element={<About />} />

        <Route path="/homestay-list" element={<Homestay />} />
        <Route path="/homestay">
          <Route path=":categoryId" element={<HomestayCategory />}></Route>
        </Route>

        <Route path="homestay-detail">
          <Route path=":homestayId" element={<HomestayDetail />} />
        </Route>

        <Route path="/product-list" element={<Product />} />
        <Route path="/product">
          <Route path=":categoryId" element={<ProductCategory />} />
        </Route>

        <Route path="/catering-list" element={<Catering />} />
        <Route path="/catering">
          <Route path=":categoryId" element={<CateringCategory />} />
        </Route>

        <Route path="/sabira/informasi" element={<InformationCenterSabira />} />
        <Route path="/kelapa/informasi" element={<InformationCenterKelapa />} />
        <Route
          path="/harapan/informasi"
          element={<InformationCenterHarapan />}
        />
        <Route
          path="/lancang/informasi"
          element={<InformationCenterLancang />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
