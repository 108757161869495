import React from "react";
import { Tab } from "@headlessui/react";
import Destination from "./Destination";
import { Link } from "react-router-dom";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const DestinationList = ({ categoryIsland }) => {
  const new_categoryIsland = [
    {
      category_island_id: "1",
      name: "Pantai Gusung Klanceng",
    },
    {
      category_island_id: "1",
      name: "Pantai Karang Mangrove",
    },
  ];

  const tourismSpotData = [
    {
      img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/gusung1.jpg?alt=media&token=b5d6495b-4b1e-45bc-9f22-5822be0357e5",
      alt: "Penginapan 1",
      to: "/destination/gusung",
      name: "Pantai Gusung Klanceng",
      deskripsi:
        "Gusung Klanceng merupakan endalan seluas 1.260 meter persegi yang menonjol ke permukaan laut. Gusung merupakan spot wisata baru di pulau lancang. Gusung Klanceng cocok untuk dijadikan sebagai tempat swafoto, berkemah, memancing maupun menunggu matahari terbit dan tenggelam.",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/netflix-clone-d26dc.appspot.com/o/karma1.jpg?alt=media&token=832c8d74-9d97-4c28-a841-821f3c7fb955",
      alt: "Penginapan 2",
      to: "/destination/karma",
      name: "Pantai Karang Mangrove",
      deskripsi:
        "Pantai Karang Mangrove -lebih populer dengan sebutan Karma- merupakan salah satu spot wisata andalan Pulau Lancang. Ingin menikmati matahari terbenam? Kunjungi Pantai Karma di sisi Barat Pulau Lancang",
    },
  ];

  return (
    <>
      <div className="mt-4 mb-4 font-bold text-center text-white font-title text-title-section xl:text-5xl">
        Wisata
      </div>

      {/* Hardcode Start */}
      <div className="grid w-4/5 grid-cols-2 grid-rows-2 gap-4 p-4 mx-auto overflow-hidden rounded-lg">
        {tourismSpotData.map((data, index) => (
          <Link
            to={data.to}
            className="relative w-full hover:shadow-lg"
            key={index}
          >
            <>
              <div className="absolute top-0 bottom-0 left-0 right-0 z-40 h-full text-lg text-white align-baseline bg-black/[0.30] hover:bg-black/[0.60]">
                <p className="absolute left-0 right-0 z-50  top-[50%] translate-y-[-50%] text-center">
                  {data.name}
                </p>
              </div>
              <img
                src={data.img}
                alt={data.alt}
                className="inset-0 object-cover object-center w-full h-full rounded-lg hover:shadow-lg hover:scale-110 hover:duration-300"
              ></img>
            </>
          </Link>
        ))}
        {/* <div className="bg-green-200">2</div> */}
      </div>

      {/* Hardcode End */}

      {/* <Tab.Group>
        <Tab.List className='flex w-1/4 p-1 mx-auto space-x-1 rounded-lg bg-blue-900/20'>
          {new_categoryIsland.map((e) => (
            <Tab
              key={e.category_island_id}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-1 text-sm font-medium leading-5 text-teal-700",
                  "ring-opacity-60 focus:outline-none",
                  selected
                    ? "bg-white shadow text-[#0E5D6E]"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {e.name}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className='mt-2'>
          {console.log(new_categoryIsland)}
          {new_categoryIsland.map((e, idx) => (
            <Tab.Panel key={idx} className='p-6'>
              <Destination
                categoryName={e?.name}
                categoryId={e?.category_island_id}
                key={idx}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group> */}
    </>
  );
};

export default DestinationList;
