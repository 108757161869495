import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";

const Product = ({ categoryName, categoryId }) => {
  const [categoryIsland, setCategoryIsland] = useState([]);

  useEffect(() => {
    async function getCategoryIsland() {
      const response = await axios.get(`${API_URL}/category-island`);
      setCategoryIsland(response.data.data);
    }
    getCategoryIsland();
  }, [categoryId]);

  return (
    <div className='main antialiased font-sans p-6'>
      <div>
        <div id='card' className=''>
          <h2
            className='text-center font-title text-3xl xl:text-4xl text-white font-bold'
            style={{ fontSize: "40px" }}
          >
            Produk
          </h2>
          {categoryIsland.map((e) => {
            return (
              <Link
                to={`/product/${e.category_island_id}`}
                key={e.category_island_id}
                className='container w-100 lg:w-4/5 mx-auto my-5 flex flex-col'
              >
                <div
                  v-for='card in cards'
                  className='flex flex-col md:flex-row overflow-hidden
                                                bg-white rounded-lg shadow-xl mt-4 w-100 mx-2'
                >
                  <div className="flex justify-center inset-0 bg-center bg-cover h-64 w-full bg-gray-500 bg-blend-multiply bg-[url('https://i.postimg.cc/W3gtCXrS/pulau-harapan.webp')]">
                    <h1
                      className='text-8xl xl:text-8xl font-title text-center px-40 py-20 text-white'
                      style={{ fontSize: "100px" }}
                    >
                      Pulau {e.name}
                    </h1>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Product;
